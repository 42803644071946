import React from 'react'
import Header from '../inc/Header'
import Slider from '../inc/Slider'
import Item from './Item'
import Map from '../pages/Map';
import ScrollToTop from "react-scroll-to-top";
import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';
import chokervidep from "../images/choker.mp4";
import Goldrate2 from './Goldrate2';

function Home() {
  return (
    <div>
      <Header />
      <br />
      <br />

      <ScrollToTop smooth top="100" color="maroon" />
      <div className="album py-1">

        <div className="container ">
          <h5>SGH JEWELLERS</h5>
        </div>
      </div>
      <div className='ring'>
        <div className='album py-1'>
          <div className="container mt-3">
            <marquee><h6> Welcome to SGH Jewellers - Krishnanagar | BIS Hallmark Certified Gold at SGH Jewellers Showroom</h6></marquee>
          </div>
        </div>
      </div>
    

   

      <Slider />

      <br />
      <Item />
      <br />
      <br />
      <Goldrate2 />

      <div className='colornew'>


        <div className="album py-0">
          <div className="container">
            <div className="row">
              <div className="col-md-6 mt-3 mb-3">
                <center>
                  <a href='tel: 03472-254990'>
                    <h3 style={{ color: "maroon" }} className="card-text"> 03472-254990 </h3>
                  </a>
                  <p className="card-text mb-1">How Can We Help You? </p>
                  <p className="card-text">  Any Questions? </p>
                </center>

              </div>
              <div className="col-md-6 mt-3 mb-3">
                <center>
                  <h3 className="card-text">GOLD RATE</h3>
                  <p className="card-text mb-1"><small>One best rate acrossIndia!</small></p>
                  <a href='goldrate'>
                    <h5 style={{ color: "maroon" }} className="card-text">View Gold Rate</h5>

                  </a>
                </center>


              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      {/* <div className="album py-2">
          <div className="container">
            <h5>Facebook Page</h5>
          </div>
            </div>
      <div className="album py-0">
          <div className="container">
         
          <iframe className="iframe-fluid" height="410" id="gmap_canvas" src="https://www.facebook.com/sghjewellers/" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
      </div>
      </div>
      <br/> */}



      <div className="album py-1">

        <div className="container ">
          <div className="accordion accordion-flush" id="accordionFlushExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingOne">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                  CHOKER VIDEO
                </button>
              </h2>
              <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                <div className="accordion-body">
                  <Video Play loop onCanPlayThrough={() => {
                    console.log('video play')
                  }}>
                    <source src={chokervidep} type="video/webm" />
                  </Video>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingTwo">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                  RING VIDEO
                </button>
              </h2>
              <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                <div className="accordion-body">
                  Update Soon
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="album py-1">

        <div className="container ">
          <h3><center>WEDDING COLLECTION</center></h3>
        </div>
      </div>
      <br />
      <Map />
      <br />
    </div>
  )
}

export default Home